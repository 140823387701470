import React, { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const navigation = [
  { name: "Integritetspolicy", href: "/integritetspolicy" },
  { name: "Användarvillkor", href: "/anvandarvillkor" },
];

function Integritetspolicy({ siteLogo }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="p-6">
      <header className="absolute inset-x-0 top-0 z-50 bg-white">
        <nav
          class="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div class="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Krono</span>
              <img alt="" src={siteLogo} className="h-6 w-auto rounded-md" />
            </a>
          </div>
          <div class="flex lg:hidden">
            <button
              type="button"
              class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span class="sr-only">Open main menu</span>
              <svg
                class="size-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
                data-slot="icon"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
          <div class="hidden lg:flex lg:gap-x-12">
            {/* <a
                      href="/krono-avtal"
                      class="text-sm/6 font-semibold text-gray-900"
                      href="#tier-enterprise"
                    >
                      Krono Avtal
                    </a> */}
            <a
              href="/integritetspolicy"
              class="text-sm/6 font-semibold text-gray-900"
            >
              Integritetspolicy
            </a>
            <a
              href="anvandarvillkor"
              class="text-sm/6 font-semibold text-gray-900"
            >
              Användarvillkor
            </a>
          </div>
        </nav>

        <Dialog
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="size-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
      <body className="text-center ">
        <h1 className="text-4xl font-bold font-poppin md:text-5xl sm:text-5xl ">
          Kronos integritetspolicy
        </h1>
        <h4 className="text-slate-500 mt-8 font-poppins">
          Gäller från och med december 2024
        </h4>

        <div className="container p-4 max-w-[648px] mx-auto my-8">
          <p className="font-poppins break-words text-left  mx-auto">
            Välkommen till KRONOS - en plattform för försäljning av klockor. Vår
            målsättning är att du, efter att ha läst denna policy, ska känna dig
            säker på att din personliga integritet respekteras och att dina
            personuppgifter behandlas korrekt. Vi arbetar därför också
            kontinuerligt med att säkerställa att vår behandling av
            personuppgifter till fullo sker i enlighet med gällande lagstiftning
            och då främst Dataskyddsförordningen (GDPR).
          </p>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md font-semibold mx-auto mb-2">
              2.1 Vad är en personuppgift och vad innebär behandling av
              personuppgifter?
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              Personuppgifter är all information som direkt, eller indirekt
              tillsammans med andra uppgifter, kan kopplas till en levande
              fysisk person. En icke-uttömmande lista av exempel på
              personuppgifter är bl.a.:
            </p>

            <div className="ml-8 mt-3">
              <ul className="text-left">
                <li className="font-poppins list-disc">Namn</li>
                <li className="font-poppins list-disc">Personnummer</li>
                <li className="font-poppins list-disc">Mail-adress</li>
                <li className="font-poppins list-disc">IP-adress</li>
                <li className="font-poppins list-disc">Bilder</li>
                <li className="font-poppins list-disc">Användardatas</li>
              </ul>
            </div>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md font-semibold mx-auto mb-2">
              2.2 Behandling av personuppgifter
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              Innefattar varje åtgärd som vidtas med personuppgifterna,
              oberoende av om den utförs automatiserat eller ej. Det här innebär
              att bl.a. följande åtgärder omfattas:
            </p>

            <div className="ml-8 mt-3">
              <ul className="text-left">
                <li className="font-poppins list-disc">Insamling</li>
                <li className="font-poppins list-disc">Registrering</li>
                <li className="font-poppins list-disc">Användning</li>
                <li className="font-poppins list-disc">Sammanförande</li>
                <li className="font-poppins list-disc">Överföring</li>
                <li className="font-poppins list-disc">Radering</li>
              </ul>
            </div>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md font-semibold mx-auto mb-2">
              3. För vem gäller policyn?
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              Olika delar av denna Integritetspolicy kan även vara relevanta för
              dig beroende på din relation till KRONOS, beroende på om du
              exempelvis är partner, köpare av klockor, säljare av klockor eller
              besöker vår hemsida eller kontaktar oss i sociala medier.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md font-semibold mx-auto mb-2">
              4. För vad gäller policyn?
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              Denna Integritetspolicy reglerar hur vi på KRONOS samlar in och
              behandlar personuppgifter för att kunna leverera och fortsätta
              utveckla vår Plattform.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md font-semibold mx-auto mb-2">
              5. Vad innebär det att vara Personuppgiftsansvarig?
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              Personuppgiftsansvarig är en fysisk eller juridisk person eller
              annat organ som bestämmer ändamålet och medlen för behandlingen av
              personuppgifter. Ett företag är personuppgiftsansvarig med
              avseende på personuppgifter de innehar för egen räkning om sina
              anställda, kunder, leverantörer och andra.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md font-semibold mx-auto mb-2">
              6. KRONOS som Personuppgiftsansvarig
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              App Labs Sweden AB 559503-4660, är personuppgiftsansvarig för den
              behandling som sker avseende dina personuppgifter inom ramen för
              KRONOS Plattform och ansvarar för att sådan behandling sker i
              enlighet med tillämplig lagstiftning.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md font-semibold mx-auto mb-2">
              7. Varför får vi behandla personuppgifter?
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              7.1 För att det ska vara tillåtet att behandla personuppgifter
              måste det alltid finnas ett stöd i GDPR, en så kallad rättslig
              grund. En sådan rättslig grund är bl.a.
            </p>

            <div className="ml-8 mt-3">
              <ul className="text-left">
                <li className="font-poppins list-disc">
                  ett samtycke från den Registrerade
                </li>

                <li className="font-poppins list-disc">
                  att personuppgiftsbehandlingen är nödvändig för att fullgöra
                  ett avtal (i det här fallet KRONOS användarvillkor) med den
                  Registrerade
                </li>
                <li className="font-poppins list-disc">
                  att fullgöra en rättslig förpliktelse, exempelvis avseende att
                  spara uppgifter p.g.a. bokföringsskyldighet
                </li>
                <li className="font-poppins list-disc">
                  att efter en intresseavvägning göra bedömningen att KRONOS
                  intresse av att behandla en personuppgift är större än den
                  registrerades intresse av skydd för densamma.
                </li>
                <li className="font-poppins list-disc">Radering</li>
              </ul>
            </div>

            <div className="mt-8">
              <p className="font-poppins break-words text-left  mx-auto">
                7.2 KRONOS behandlar alltid dina personuppgifter i enlighet med
                tillämplig lagstiftning. Vår främsta grund för att behandla dina
                personuppgifter är att det är nödvändigt för att fullgöra vårt
                avtal med dig angående användning av vår plattform.
              </p>
            </div>

            <div className="mt-8">
              <p className="font-poppins break-words text-left  mx-auto">
                7.3 Det finns också situationer där vi har ett berättigat
                intresse av att behandla dina personuppgifter, t.ex. vid
                intresse av att få marknadsföra oss mot besökare i våra digitala
                kanaler eller vid intresse av att utveckla vår plattform.
              </p>
            </div>

            <div className="mt-8">
              <p className="font-poppins break-words text-left  mx-auto">
                7.4 För det fall att vi på KRONOS skulle behandla dina
                personuppgifter för något ändamål som enligt tillämplig
                lagstiftning kräver ditt samtycke, så kommer vi alltid att
                inhämta ditt samtycke i förväg.
              </p>
            </div>

            <div className="mt-8">
              <p className="font-poppins break-words text-left  mx-auto">
                7.5 Det kan förekomma att samma personuppgift behandlas både med
                stöd av fullgörande av ett avtal, specifikt av samtycke eller
                med stöd av att uppgiften är nödvändig för att uppfylla andra
                rättsliga förpliktelser.
              </p>
              <p className="font-poppins break-words text-left  mx-auto">
                Detta innebär att även om du återkallar ditt samtycke och den
                behandling som grundas på samtycket upphör, kan personuppgiften
                ändå finnas kvar hos oss för andra ändamål. I huvudsak behandlar
                vi dock endast dina uppgifter för att fullgöra ett avtal i
                vilket du är part. För mer information, kontakta
                info@kronosapp.se
              </p>
            </div>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md font-semibold mx-auto mb-2">
              8. Vilka personuppgifter samlar vi in?
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              I den här delen av vår policy vill vi ge dig några exempel på hur
              vi hanterar personuppgifter för att garantera att vi kontinuerligt
              levererar fantastiska upplevelser med en hög servicenivå.
            </p>

            <p className="font-poppins break-words text-left mx-auto mt-3">
              8.1 När du registrerar ett användarkonto på vår plattform När du
              registrerar ett användarkonto på vår plattform så hanterar vi
              följande uppgifter som du själv lämnar till oss:
            </p>

            <div className="ml-8 mt-3">
              <ul className="text-left">
                <li className="font-poppins list-disc">Namn</li>
                <li className="font-poppins list-disc">Födelseår</li>
                <li className="font-poppins list-disc">E-postadress</li>
              </ul>
            </div>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md mx-auto mb-2">
              8.1.1 Vi hanterar dina personuppgifter för att:
            </p>

            <div className="ml-8 mt-3">
              <ul className="text-left">
                <li className="font-poppins list-disc">
                  Identifiera dig i anslutning till ditt användande av
                  plattformen
                </li>
                <li className="font-poppins list-disc">
                  Debitera dig i den mån vi tar betalt för användning av
                  tjänsten eller vid annonsering
                </li>
                <li className="font-poppins list-disc">
                  Kontakta dig med information som berör ditt användande av
                  plattformen, via t.ex. e-post och sms
                </li>
                <li className="font-poppins list-disc">
                  Ta fram statistik och genomföra analyser i syfte för att
                  förbättra vår plattform och dess erbjudanden
                </li>
                <li className="font-poppins list-disc">
                  Tillhandahålla, underhålla, testa, förbättra och utveckla vår
                  plattform och den tekniska plattform som används för att
                  tillhandahålla denna
                </li>
                <li className="font-poppins list-disc">
                  Säkerställa säkerheten för vår plattform, för att upptäcka
                  eller förhindra olika typer av olaglig användning eller
                  användning som på annat sätt strider mot våra användarvillkor
                </li>
                <li className="font-poppins list-disc">
                  Informera dig om personliga och anpassade erbjudanden,
                  kampanjer och förmåner från oss och våra samarbetspartners,
                  via t.ex. e-post och sms
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md mx-auto mb-2">
              8.1.2 Rättslig grund för hanteringen:
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              Vi hanterar dina personuppgifter med stöd i fullgörande av avtal
              när vi uppfyller våra åtaganden gentemot dig som Användare (t.ex.
              när vi administrerar service kopplat till ditt användarkonto och
              när vi tillhandahåller relevanta erbjudanden) och med stöd av
              intresseavvägning när vi har ett berättigat intresse att använda
              uppgifter om din aktivitet på plattformen för att ta fram
              statistik och för att utveckla, förbättra och säkerställa
              säkerheten för vår plattform.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md mx-auto mb-2">
              8.1.3 Lagringstid:
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              Vi sparar dina personuppgifter i högst 24 månader efter det att du
              senast genomfört ett köp eller på annat sätt interagerat med vår
              plattform samt i förekommande fall i sju år i form av
              räkenskapsmaterial (i enlighet med gällande lagstiftning).
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md font-semibold mx-auto mb-2">
              8.2 När du betalar för våra tjänster
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              Betalning erläggs i direkt anslutning till annonsering av klockor
              eller i samband med köp (vi informerar dig kring eventuella
              kostnader förenade med användningen i samband med annonsering
              eller köp av klockor). Betalningen hanteras antingen via våra
              samarbetspartners eller direkt genom fakturering.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left font-semibold text-md mx-auto mb-2">
              8.3 När du kommunicerar med oss
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              Du kan välja att kommunicera med oss på flertalet sätt, bland
              annat genom våra konton i sociala medier eller via epost,
              info@kronosapp.se.
            </p>

            <div className="ml-8 mt-3">
              <p className="font-poppins break-words text-left my-3 mx-auto">
                När du kommunicerar med oss så hanterar vi följande uppgifter
                som du själv lämnar till oss:
              </p>

              <ul className="text-left">
                <li className="font-poppins list-disc">
                  Ditt namn och kontaktinformation (telefonnummer, epost)
                </li>
                <li className="font-poppins list-disc">
                  Information om din fråga, synpunkt eller ärende
                </li>
                <li className="font-poppins list-disc">
                  Övrig information som du bestämmer dig för att inkludera i
                  ditt meddelande
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md mx-auto mb-2">
              8.3.1 Vi hanterar dina personuppgifter för att:
            </p>

            <div className="ml-8 mt-3">
              <ul className="text-left">
                <li className="font-poppins list-disc">
                  Besvara dina frågor och hantera ditt ärende, exempelvis genom
                  att avhjälpa fel och hantera klagomål
                </li>
                <li className="font-poppins list-disc">
                  Förbättra våra tjänster och den information som vi
                  kommunicerar via våra olika kanaler
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md mx-auto mb-2">
              8.3.2 Rättslig grund för hanteringen:
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              Vi hanterar dina personuppgifter med stöd i vårt och ditt
              berättigade intresse av att hantera ärendet (intresseavvägning)
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md mx-auto mb-2">
              8.3.3 Lagringstid:
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              För att säkra spårbarhet sparar vi uppgifter om vår kommunikation
              med dig i 12 månader.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left text-md mx-auto mb-2">
              8.3.4 Vi hanterar dina personuppgifter för att:
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              Vi hanterar dina personuppgifter med stöd i vårt och ditt
              berättigade intresse av att hantera ärendet (intresseavvägning)
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left font-semibold text-md mx-auto mb-2">
              8.4 Användning av vår plattform
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              Kronos samlar in data kopplat till ditt användande av vår
              plattform. Sådan data innefattar exempelvis
            </p>

            <div className="ml-8 mt-3">
              <ul className="text-left">
                <li className="font-poppins list-disc">
                  information om din typ av användarkonto och dina interaktioner
                  med plattformen och dess tillhörande applikationer
                </li>
                <li className="font-poppins list-disc">
                  tekniska data, vilket kan innefatta URL-information,
                  cookie-data, din IP-adress, nätverksinformation samt de typer
                  av enheter du använder för att få tillgång till vår plattform
                </li>
                <li className="font-poppins list-disc">
                  tekniska data via Google Analytics
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left font-semibold text-md mx-auto mb-2">
              9. För vilka ändamål behandlas personuppgifter?
            </p>
            <p className="font-poppins break-words text-left  mx-auto">
              De personuppgifter om dig som samlas in i anslutning till att du
              använder Kronos plattform kommer att behandlas av Kronos, eller
              våra samarbetspartners, för följande ändamål:
            </p>
            <p className="font-poppins break-words text-left my-1 mx-auto">
              Kronos samlar in data kopplat till ditt användande av vår
              plattform. Sådan data innefattar exempelvis:
            </p>

            <div className="ml-8 mt-3">
              <ul className="text-left">
                <li className="font-poppins list-disc">
                  för att tillhandahålla, personanpassa och förbättra din
                  upplevelse av plattformen samt administrera vårt avtal med
                  dig.
                </li>
                <li className="font-poppins list-disc">
                  För att vi ska kunna kommunicera med dig, exempelvis för att
                  möjliggöra kundvård och kundservice, vid kontakt via telefon,
                  epost, notifikationer samt genom våra konton i sociala medier.
                </li>
                <li className="font-poppins list-disc">
                  För att behandla dina betalningar samt förhindra eller
                  upptäcka bedrägerier.
                </li>
                <li className="font-poppins list-disc">
                  För att ta fram statistik över användningen av våra digitala
                  kanaler och plattformen.
                </li>
                <li className="font-poppins list-disc">
                  För att underhålla, utveckla, testa och förbättra våra
                  digitala kanaler och de tekniska plattformar som de
                  tillhandahålls på.
                </li>
                <li className="font-poppins list-disc">
                  För marknadsföringsändamål, däribland för marknadsföring via
                  epost, som du givetvis kan avregistrera dig ifrån via en länk
                  i varje separat utskick.
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left font-semibold text-md mx-auto mb-2">
              10. Våra åtgärder för att skydda dina personuppgifter
            </p>
            <p className="font-poppins break-words text-left mt-8 mx-auto">
              10.1 Vi på KRONOS har säkerställt att vi har vidtagit lämpliga
              tekniska och organisatoriska åtgärder för att skydda dina
              personuppgifter mot bland annat förlust, missbruk och obehörig
              åtkomst.
            </p>

            <p className="font-poppins break-words text-left mt-8 mx-auto">
              10.2 För att tekniskt sett tillförsäkra att personuppgifter
              behandlas på ett säkert och konfidentiellt sätt använder vi oss av
              digitala nätverk som är intrångsskyddade med hjälp av exempelvis
              kryptering, brandväggar och lösenordsskydd. Vidare finns
              utvecklade system för att hantera och vid behov, genom
              behörighetsstyrning, begränsa tillgång till diverse
              personuppgifter, baserat på deras karaktär och känslighet. För det
              fall att ett intrång äger rum har KRONOS skapat goda rutiner för
              identifiering, skademinimering och rapportering. Slutligen har
              KRONOS även utarbetat en väl fungerande metod för att tillgodose
              den registrerades rättigheter, bland annat avseende rätten att bli
              bortglömd.
            </p>

            <p className="font-poppins break-words text-left mt-8 mx-auto">
              10.3 KRONOS har organisatoriskt sett säkerställt att samtliga
              anställda, konsulter samt de leverantörer som KRONOS använder för
              att tillhandahålla vår plattform är bundna av sekretessavtal och
              är skyldiga att följa KRONOS regler för informations- och
              IT-säkerhet samt denna Integritetspolicy. För att säkerställa en
              god kunskapsnivå avseende behandling av personuppgifter arrangeras
              löpande utbildningar, både för KRONOS anställda samt de konsulter
              som från tid till annan anlitas för att utföra uppdrag åt bolaget.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left font-semibold text-md mx-auto mb-2">
              11. När delar vi med oss av personuppgifter?
            </p>
            <p className="font-poppins break-words text-left mt-8 mx-auto">
              11.1 Vi på Kronos har säkerställt att vi har vidtagit lämpliga
              tekniska och organisatoriska åtgärder för att skydda dina
              personuppgifter mot bland annat förlust, missbruk och obehörig
              åtkomst.
            </p>

            <p className="font-poppins break-words text-left mt-8 mx-auto">
              11.2 Kronos kommer inte att sälja, lämna ut eller sprida
              personuppgifter till tredje part, med undantag för vad som anges i
              denna Integritetspolicy. Inom ramen för vår plattform kan
              personuppgifter lämnas vidare till exempelvis underleverantörer
              och samarbetspartners, om det är nödvändigt för utförandet och
              tillhandahållandet av Plattformen. För de fall att vi väljer att
              dela med oss av personuppgifter ingår vi ett s.k.
              personuppgiftsbiträdesavtal för att säkerställa att mottagaren av
              personuppgifterna behandlar dessa uppgifter i enlighet med
              tillämplig lagstiftning samt att mottagaren har vidtagit
              nödvändiga tekniska och organisatoriska åtgärder enligt GDPR för
              att på ett tillfredställande sätt kunna skydda den registrerades
              rättigheter och friheter.erades rättigheter, bland annat avseende
              rätten att bli bortglömd.
            </p>

            <p className="font-poppins break-words text-left mt-8 mx-auto">
              11.3 Vidare kan vi lämna ut personuppgifter om vi är skyldiga till
              det enligt gällande lag, domstolsbeslut eller om sådant utlämnande
              annars är nödvändigt för att medverka till en rättslig utredning.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left font-semibold text-md mx-auto mb-2">
              12. Dina rättigheter
            </p>
            <p className="font-poppins break-words text-left mt-8 mx-auto">
              12.1 KRONOS ansvarar för att dina personuppgifter behandlas i
              enlighet med gällande lagstiftning.
            </p>

            <p className="font-poppins break-words text-left mt-8 mx-auto">
              12.2 KRONOS kommer på din begäran eller på eget initiativ att
              rätta, avidentifiera, radera eller komplettera uppgifter som
              upptäcks vara felaktiga, ofullständiga eller missvisande.
            </p>

            <p className="font-poppins break-words text-left mt-8 mx-auto">
              12.3 Du har rätt att av begära tillgång till dina personuppgifter.
              Det innebär att du har rätt att begära ett registerutdrag över den
              behandling som vi genomför avseende dina personuppgifter. Du har
              även rätt att få en kopia av de personuppgifter som behandlas. Du
              har rätt att en gång per kalenderår, genom en skriftligt
              undertecknad ansökan, kostnadsfritt erhålla ett registerutdrag
              från om vilka personuppgifter som finns registrerade om dig,
              ändamålen med behandlingen och till vilka mottagare uppgifterna
              har lämnats eller ska lämnas ut. Du har även rätt att i
              registerutdraget få information om den förutsedda period under
              vilken uppgifterna kommer att lagras eller kriterierna som används
              för att fastställa denna period.
            </p>

            <p className="font-poppins break-words text-left mt-8 mx-auto">
              12.4 Du har rätt till rättelse av dina personuppgifter. Vi kommer
              på din begäran att så snabbt som möjligt rätta de felaktiga eller
              ofullständiga uppgifter vi behandlar om dig.
            </p>

            <p className="font-poppins break-words text-left mt-8 mx-auto">
              12.5 Du har rätt till radering av dina personuppgifter. Det
              innebär att du har rätt att begära att dina personuppgifter tas
              bort om de inte längre är nödvändiga för det ändamål de samlades
              in för. Det kan dock finnas lagkrav på att vi inte omedelbart får
              radera dina personuppgifter i exempelvis bokförings- och
              skattelagstiftning. Vi kommer då att avsluta den behandling som
              görs för andra ändamål än att följa lagstiftningen.
            </p>

            <p className="font-poppins break-words text-left mt-8 mx-auto">
              12.6 Du har rätt att invända mot personuppgiftsbehandling som
              utförs med stöd av en intresseavvägning. Om du invänder mot sådan
              behandling kommer vi enbart att fortsätta behandlingen om det
              finns berättigade skäl till behandlingen som väger tyngre än dina
              intressen.
            </p>

            <p className="font-poppins break-words text-left mt-8 mx-auto">
              12.7 Om du inte vill att behandlar dina personuppgifter för direkt
              marknadsföring har du alltid rätt att invända mot sådan behandling
              genom att antingen avregistrera dig direkt i varje specifikt
              epostmeddelande, alternativt skicka ett mail till
              info@kronosapp.se. När har vi mottagit din invändning kommer vi
              att upphöra med att behandla personuppgifterna för sådant
              marknadsföringsändamål. Om du inte är nöjd med hur vi hanterar
              dina personuppgifter har du också möjlighet att anmäla vår
              behandling av dina personuppgifter till
              Integritetsskyddsmyndigheten. För smidig och effektiv hantering
              rekommenderar vi dock att du i första hand hör av dig till oss så
              att vi kan hjälpa dig med eventuella frågor och funderingar.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left font-semibold text-md mx-auto mb-2">
              13. Ändringar i denna policy
            </p>
            <p className="font-poppins break-words text-left mt-4 mx-auto">
              KRONOS förbehåller sig rätten att revidera denna Integritetspolicy
              från tid till annan. Datumet för den senaste ändringen anges i
              slutet av Integritetspolicyn. Om vi gör några ändringar i
              Integritetspolicyn kommer vi att publicera dessa ändringar på
              plattformen. Du rekommenderas därför att läsa denna
              Integritetspolicy regelbundet för att uppmärksamma eventuella
              ändringar.
            </p>
            <p className="font-poppins break-words text-left mt-4 mx-auto">
              Om vi ändrar Integritetspolicyn på ett sätt som väsentligt skiljer
              sig från vad som angavs när ditt samtycke samlades in, kommer vi
              att skriftligen underrätta dig om dessa förändringar och vid behov
              be dig om att på nytt samtycka till KRONOS
              personuppgiftsbehandling.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-left font-semibold text-md mx-auto mb-2">
              14. Kontakt
            </p>
            <p className="font-poppins break-words text-left mt-4 mx-auto">
              Tveka inte att kontakta oss om du har några frågor om denna
              Integritetspolicy, behandlingen av dina personuppgifter eller om
              du vill begära ett registerutdrag.
            </p>
            <p className="font-poppins break-words text-left mt-4 mx-auto">
              E-post:{" "}
              <a
                href="mailto:info@kronosapp.se"
                className="font-medium text-blue-700 dark:text-blue-500 hover:underline"
              >
                info@kronosapp.se
              </a>
            </p>
            <p className="font-poppins break-words text-left mt-4 mx-auto">
              Om du har ytterligare frågor eller behöver ytterligare
              förtydliganden kring Användarvillkoren eller Integritetspolicyn,
              är du välkommen att kontakta oss.
            </p>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Integritetspolicy;
