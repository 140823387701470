import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Layout({ children }) {
  const siteLogo = "path-to-your-logo-image";

  useEffect(() => {
    // Load the Google Tag Manager script
    const gtagScript = document.createElement("script");
    gtagScript.async = true;
    gtagScript.src =
      "https://www.googletagmanager.com/gtag/js?id=AW-11556258873";
    document.head.appendChild(gtagScript);

    // Initialize Google Analytics
    gtagScript.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      gtag("js", new Date());
      gtag("config", "AW-11556258873");
    };
  }, []);

  return (
    <>
      <header className="absolute inset-x-0 top-0 z-50 bg-white">
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <nav className="flex items-center justify-between p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Krono</span>
              <img
                alt="Krono Logo"
                src={siteLogo}
                className="h-12 w-auto rounded-md"
              />
            </Link>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {/* <Link
              to="/krono-avtal"
              className="text-sm font-semibold text-gray-900 font-poppins"
            >
              Krono Avtal
            </Link> */}
            <Link
              to="/integritetspolicy"
              className="text-sm font-semibold text-gray-900 font-poppins"
            >
              Integritetspolicy
            </Link>
            <Link
              to="/anvandarvillkor"
              className="text-sm font-semibold text-gray-900 font-poppins"
            >
              Användarvillkor
            </Link>
          </div>
        </nav>
      </header>
      <main className="mt-24">{children}</main>
      <footer className="bg-gray-50 py-4 text-center text-gray-600 font-poppins">
        <p className="my-6">
          Kontakt:{" "}
          <a href="mailto:info@kronosapp.se" className="underline">
            info@kronosapp.se
          </a>
        </p>
        © 2024 Kronos - App Labs Sweden AB
      </footer>
    </>
  );
}

export default Layout;
