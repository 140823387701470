import React from "react";

function KronoAvtal({ siteLogo }) {
  return (
    <div className="p-6">
      <header className="absolute inset-x-0 top-0 z-50 bg-white">
        <nav className="flex items-center justify-between p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Krono</span>
              <img
                alt="Krono Logo"
                src={siteLogo}
                className="h-6 w-auto rounded-md"
              />
            </a>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {/* <a
              href="/krono-avtal"
              className="text-sm font-semibold text-gray-900"
            >
              Krono Avtal
            </a> */}
            <a
              href="/integritetspolicy"
              className="text-sm font-semibold text-gray-900"
            >
              Integritetspolicy
            </a>
            <a
              href="/anvandarvillkor"
              className="text-sm font-semibold text-gray-900"
            >
              Användarvillkor
            </a>
          </div>
        </nav>
      </header>
      <h1 className="text-4xl font-bold">Krono Avtal</h1>
      <p className="mt-4 text-gray-600">
        Här kan du läsa om våra avtal och hur vi säkerställer säkra
        transaktioner...
      </p>
    </div>
  );
}

export default KronoAvtal;
