import React, { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const navigation = [
  { name: "Integritetspolicy", href: "/integritetspolicy" },
  { name: "Användarvillkor", href: "/anvandarvillkor" },
];

function Anvandarvillkor({ siteLogo }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="p-6">
      <header className="absolute inset-x-0 top-0 z-50 bg-white">
        <nav
          class="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div class="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Krono</span>
              <img alt="" src={siteLogo} className="h-6 w-auto rounded-md" />
            </a>
          </div>
          <div class="flex lg:hidden">
            <button
              type="button"
              class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span class="sr-only">Open main menu</span>
              <svg
                class="size-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
                data-slot="icon"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
          <div class="hidden lg:flex lg:gap-x-12">
            {/* <a
                      href="/krono-avtal"
                      class="text-sm/6 font-semibold text-gray-900"
                      href="#tier-enterprise"
                    >
                      Krono Avtal
                    </a> */}
            <a
              href="/integritetspolicy"
              class="text-sm/6 font-semibold text-gray-900"
            >
              Integritetspolicy
            </a>
            <a
              href="anvandarvillkor"
              class="text-sm/6 font-semibold text-gray-900"
            >
              Användarvillkor
            </a>
          </div>
        </nav>

        <Dialog
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="size-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
      <body className="text-center ">
        <h1 className="text-4xl font-bold font-poppin md:text-5xl sm:text-5xl">
          Kronos användarvillkor
        </h1>

        <h4 className="text-slate-500 mt-8 font-poppins">
          Gäller från och med december 2024
        </h4>

        <div className="container p-4 max-w-[648px] mx-auto my-8">
          <p className="font-poppins break-words text-start  mx-auto">
            Vi är väldigt glada att du valt att använda vår plattform. Här nedan
            följer våra användarvillkor för att ge dig en bättre bild av vad som
            gäller när du använder våra tjänster. Tveka dock aldrig att höra av
            dig till oss (kontaktuppgifter följer nedan) om du har några frågor!
          </p>

          <p className="font-poppins break-words text-start mt-8  mx-auto">
            Dessa användarvillkor (&quot;Villkoren&quot;) reglerar din
            användning av plattformen KRONOS (”Plattformen” eller
            &quot;Tjänsten&quot;), som tillhandahålls av Företaget App Labs
            Sweden AB (&quot;vi&quot;, &quot;oss&quot; eller &quot;vår&quot;).
          </p>

          <p className="font-poppins break-words text-start mt-8 mx-auto">
            Tack för att du har valt Kronos och ett extra stort tack för att du
            tar dig tid att noggrant läsa igenom våra användarvillkor. Vi vill
            börja med att i en kort sammanfattning förklara varför vi har skapat
            dessa användarvillkor. Vårt grundläggande mål är att på ett tydligt
            och lättläst vis
          </p>

          <div className="mt-8">
            <div className="ml-8 mt-3">
              <ul className="text-start">
                <li className="font-poppins list-disc">
                  förklara vilken roll som du som företag eller privatperson
                  (”användare”) och vi har i detta sammanhang
                </li>
                <li className="font-poppins list-disc">
                  förklara hur du som användare ska agera för att vi på KRONOS
                  ska kunna leverera och fortsätta utveckla vår Plattform för
                  klockentusiaster
                </li>
                <li className="font-poppins list-disc">
                  säkerställa att du förstår de villkor som gäller för att du
                  ska få använda vår plattform
                </li>
              </ul>
            </div>
          </div>

          <p className="font-poppins break-words text-start mt-8 mx-auto">
            Vår målsättning är att du, efter att ha läst dessa villkor, ska
            känna dig säker på ditt val av Kronos och plattformen som vi
            tillhandahåller. Om du har några frågor eller funderingar är du
            givetvis varmt välkommen att höra av dig till oss. Tack för ditt
            förtroende och tack för att du använder Kronos!
          </p>

          <p className="font-poppins break-words text-start mt-8 mx-auto">
            Dessa användarvillkor gäller då du besöker vår hemsida eller
            använder vår eventuella webb- och/eller mobilbaserade applikation.
          </p>

          <div className="mt-8">
            <h2 className="font-poppins break-words text-start text-lg font-semibold mx-auto mb-2">
              Information om Kronos
            </h2>
            <p className="font-poppins break-words text-start  mx-auto">
              Ornos (App Labs Sweden AB, Org.nr. 559503-4660), tillhandahåller
              en plattform för säker försäljning av klockor via den egna
              webbplatsena{" "}
              <a
                href="https://www.kronosapp.se"
                className="font-medium text-blue-700 dark:text-blue-500 hover:underline"
              >
                https://www.kronosapp.se
              </a>
              .
            </p>

            <p className="font-poppins break-words text-start  mx-auto">
              Kronos är en applikation för att köpa och sälja klockor.
            </p>

            <p className="font-poppins break-words text-start mt-8 mx-auto">
              Genom att genomföra ett köp godkänner du våra villkor. Kronos
              tillhandahåller möjligheterna till försäljning av klockor (men har
              ingen egen försäljning) via vår plattform ”Plattform”.
            </p>

            <p className="font-poppins break-words text-start mt-8 mx-auto">
              Denna tjänst innebär att säljaren lämnar in sin klocka till en
              partner som förvarar den tryggt. Köparen informeras via
              Kronos-appen när klockan finns tillgänglig att se hos partnern,
              men känner inte till exakt när den lämnades in.
            </p>

            <p className="font-poppins break-words text-start mt-8  mx-auto">
              När köparen besöker partnern för att inspektera klockan, visar de
              upp en kod i Kronos-appen, och visningen sker under noggrant
              övervakade förhållanden tillsammans med partnerns personal.
            </p>

            <p className="font-poppins break-words text-start mt-8 mx-auto">
              Om köpet genomförs betalar köparen till partnern, som förmedlar
              pengarna till säljaren. Om köpet inte går igenom, får säljaren ett
              meddelande via appen och kan hämta ut sin klocka vid en tidpunkt
              som passar.
            </p>

            <p className="font-poppins break-words text-start mt-8 mx-auto">
              Våra huvudsakliga användare/kunder är privatpersoner medan våra
              partners huvudsakligen kommer att bestå av pantbanker och
              klockbutiker.
            </p>

            <p className="font-poppins break-words text-start mt-8 mx-auto">
              Kontakt sker via mail:{" "}
              <a
                href="mailto:info@kronosapp.se"
                className="font-medium text-blue-700 dark:text-blue-500 hover:underline"
              >
                info@kronosappen.se
              </a>
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              1. Godkännande av Villkor och Datainsamling
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Genom att använda vår applikation samtycker du till att data
              lagras och sparas i vår databas till nedan angivna villkor.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              2. Plattformens Roll och Ansvarsbegränsning
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              kronos agerar endast som en plattform för köp och försäljning av
              klockor. Vi tar inget ansvar för eventuellt förfalskade varor;
              ansvaret ligger helt hos köpare och säljare. KRONOS ansvarar inte
              för hur en affär genomförs mellan köpare och säljare genom
              Plattformen.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              3. Annonsering
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Samtliga priser på plattformen anges i SEK och är inklusive 25%
              moms. Vi förbehåller oss rätten att justera priser då
              felaktigheter uppstått p.g.a. felaktig information, tryckfel eller
              prisändringar från berörda samarbetspartners.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              2. Plattformens Roll och Ansvarsbegränsning
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Kronos agerar endast som en plattform för köp och försäljning av
              klockor. Vi tar inget ansvar för eventuellt förfalskade varor;
              ansvaret ligger helt hos köpare och säljare. KRONOS ansvarar inte
              för hur en affär genomförs mellan köpare och säljare genom
              Plattformen.
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Vi förbehåller oss rätten att när som helst ändra i villkoren. Om
              vi gör detta kommer ändringarna att läggas upp på den här sidan.
              Längst upp på sidan kommer vi att ange det datum då villkoren
              senast reviderades. Din fortsatta användning av Tjänsten efter
              detta datum utgör ditt godkännande av de nya användarvillkoren.
            </p>

            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Kronos förbehåller sig rätten att när som helst och utan
              förvarning stänga ner eller ta bort en annons som bryter mot dessa
              Villkor eller som vi anser olämpliga.
            </p>

            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Notera att Kronos ej har egen försäljning via Plattformen och att
              det är användarna som hanterar marknadsplatsen genom att köpa och
              sälja klockor med hjälp av de partners KRONOS samarbetar med.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              4. Användaransvar
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Användaren ansvarar för att tillhandahålla korrekta och
              uppdaterade personuppgifter samt för att använda Tjänsten i
              enlighet med tillämpliga lagar och bestämmelser. Användaren åtar
              sig att inte missbruka Tjänsten, inklusive att inte använda den
              för olagliga ändamål eller främja oetiska handlingar.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              5. Konto och Säkerhet
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Inloggning sker via BankID. Användaren är ansvarig för att skydda
              sitt konto och sina inloggningsuppgifter. Eventuell obehörig
              åtkomst till användarkontot bör omedelbart rapporteras till
              KRONOS.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              6. Ändringar av Tjänsten
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              KRONOS förbehåller sig rätten att ändra eller avbryta delar av
              eller hela Plattformen när som helst. Användarna kommer att
              skriftligen informeras om sådana ändringar i förväg i den mån det
              är möjligt.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              7. Användarens Åtaganden Användaren åtar sig att:
            </p>
            <div className="ml-8 mt-3">
              <ul className="text-start">
                <li className="font-poppins list-disc">
                  Inte bryta mot några tillämpliga lagar eller regler.
                </li>
                <li className="font-poppins list-disc">
                  Inte lägga upp innehåll som är olagligt, kränkande,
                  vilseledande eller på annat sätt olämpligt.
                </li>
                <li className="font-poppins list-disc">
                  Inte försöka kringgå säkerhetsåtgärder på Tjänsten.
                </li>
                <li className="font-poppins list-disc">
                  Inte sälja falska klockor eller varor eller i övrigt ange
                  felaktiga eller vilseledande uppgifter kring klockor eller
                  varor Användaren säljer.
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              8. Ansvarsbegränsning
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              I den utsträckning som tillåts enligt lag ansvarar KRONOS inte för
              direkta, indirekta, tillfälliga eller följdskador som uppstår från
              din användning av Tjänsten.
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              KRONOS tillhandahåller endast en tjänst varvid det är upp till
              användarna av plattformen att köpa och sälja varor till varandra.
              KRONOS ansvarar ej för objekten som säljs genom Plattformen.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              9. Friskrivning
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Tjänsten tillhandahålls i befintligt skick utan garantier av något
              slag. KRONOS garanterar inte att Tjänsten kommer att vara fri från
              fel eller avbrott.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              10. Immateriella Rättighetert
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Allt innehåll på Tjänsten, inklusive men inte begränsat till text,
              grafik och logotyper, är vår egendom eller licensierat till oss
              och skyddas av tillämplig lagstiftning. Användaren förvärvar inga
              rättigheter i KRONOS immateriella rättigheter.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              11. Ändringar av Villkoren
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Vi förbehåller oss rätten att när som helst ändra dessa Villkor.
              Ändringar träder i kraft när de publiceras på Tjänsten. Din
              fortsatta användning av Tjänsten efter sådana ändringar utgör ditt
              godkännande av de nya Villkoren.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              12. Force Majeure Part
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              är befriad från påföljd för underlåtenhet att fullgöra viss
              förpliktelse enligt detta avtal, om underlåtenheten har sin grund
              i omständighet över vilken sådan part inte råder och som
              förhindrar fullgörandet t.ex. strejk, lockout eller dylikt liksom
              myndighetsingripande eller andra omständigheter utanför parts
              kontroll.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              13. Övrigt
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Övrigt Oaktat eventuella motsägelser i dessa Villkor, skall
              användaren utföra, fullgöra och underhålla sin avtalsenliga
              relation till oss så att ingen handling eller underlåtelse utgör,
              orsakar eller bidrar till att KRONOS blir ersättningsskyldig mot
              dess partners eller övriga användare. Vi förbehåller oss också
              rätten att ändra regler och villkor, och din användning av
              Plattformen efter att sådana ändringar genomförts ska anses utgöra
              ditt medgivande till sådana modifierade Villkor. Du samtycker till
              att regelbundet granska dessa Villkor för att kontrollera om de
              har ändrats. Om dessa Villkor skulle ändras kommer vi att sträva
              efter att på lämpligt sätt meddela detta via Plattformen eller per
              e-post.
            </p>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              14. Användarens åtaganden samt användning av Plattforme
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Användaren ansvarar för att förhålla sig till KRONOS vid var tid
              gällande villkor. Användaren skall erlägga betalning i enlighet
              med villkoren som överenskommits med KRONOS.
            </p>

            <div className="ml-8 mt-3">
              <ul className="text-start">
                <li className="font-poppins list-disc">
                  (i) bryter eller kränker någon annans rätt till publicitet,
                  integritet, upphovsrätt, varumärke eller annan immateriell
                  rättighet.
                </li>
                <li className="font-poppins list-disc">
                  (ii) är vilseledande, bedrägligt, kriminellt eller olagligt;
                </li>
                <li className="font-poppins list-disc">
                  (iii) kan vara obscent, opassande, pornografiskt, vulgärt,
                  kränkande, rasistiskt, sexistiskt, diskriminerande,
                  förolämpande, nedsättande, skadligt, trakasserande,
                  skrämmande, besvärande, elakt eller hotfullt
                </li>
                <li className="font-poppins list-disc">
                  (iv) föreställer en annan person eller förvränger ett
                  förhållande till en annan person;
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Vid användning av KRONOS Plattform samt övriga tjänsteutbud
              garanterar Användaren också att denne inte, utan att först ha
              inhämtat skriftligt samtycke från KRONOS, kommer att:
            </p>

            <div className="ml-8 mt-3">
              <ul className="text-start">
                <li className="font-poppins list-disc">
                  (i) använda varumärken, logotyper, design, fotografier,
                  instruktionsvideor eller annat material som används i vår
                  Plattform.
                </li>
                <li className="font-poppins list-disc">
                  (ii) kopiera, arkivera, ladda ner, ladda upp, distribuera,
                  sända, framföra, visa, göra tillgängligt eller på annat sätt
                  använda någon del av vara tjänster eller innehållet i
                  Plattformen utöver vad som anges i Villkoren.
                </li>
                <li className="font-poppins list-disc">
                  (iii) använda Plattform, några verktyg som tillhandahålls
                  genom Plattformen eller något innehåll i Plattformen för något
                  kommersiellt syfte, för vilket ett skriftligt samtycke från
                  KRONOS saknas.
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-8">
            <p className="font-poppins break-words text-start mt-8 mx-auto text-md font-semibold">
              15. Plattformens tillgänglighet, säkerhet och riktighet
            </p>
            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Trots att Kronos eftersträvar att ständigt ha Plattformen
              tillgänglig, kan KRONOS inte hållas ansvariga för det fall att
              Plattformen av någon anledning inte skulle vara tillgänglig.
            </p>

            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Kronos kan därmed inte garantera att Användarens tillgång till
              Plattformen kommer vara oavbruten och utan fel. Dessutom kan
              Kronos då och då vara tvungna att genomföra reparationer och
              underhåll av Plattformen som kan påverka dess tillgänglighet och
              funktionalitet.
            </p>

            <p className="font-poppins break-words text-start mt-2 mx-auto text-md">
              Tillgång till Plattformen kan när som helst stängas av för dig som
              Användare, likaså för samtliga användare av Plattformen,
              tillfälligt eller permanent och utan förvarning. Kronos kan också
              införa restriktioner för hur någon del av Plattformen används,
              utan att behöva meddela Användaren i förväg eller ange skäl för
              införandet av restriktionerna i den mån något avtalsbrott har
              upptäckts.
            </p>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Anvandarvillkor;
